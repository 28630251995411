<template>

  <div>
    <div
      class="sh-auth"
      id="sh_auth"
    >
      <div>
        <div class="logo">
          <img src="/media/logo_auth.svg" alt="">
        </div>
        <br/>
        <div
          class="sh-auth-content create"
        >
          <div class="d-flex flex-column-fluid flex-center">
            <!--begin::Form-->
            <div style="width: 80vw">
              <b-row>
                <b-col lg="4" md="12">
                  <div>
                    <div class="container">
                      <div class="w-100">
                        <div>
                          <div class="get-started-title">
                            {{ $t("AUTH.MENTOR_ACCOUNT") }}
                          </div>

                          <div class="get-started-subtitle" style="margin-bottom: 40px">
                            {{ $t("AUTH.MENTOR_ACCOUNT_DESCRIPTION") }}
                          </div>
                          <div>
                            <div class="info">
                              <div class="ellipse blue"></div>
                              {{ $t("AUTH.MENTOR_INFO_1") }}
                            </div>
                          </div>
                          <div>
                            <div class="info">
                              <div class="ellipse blue "></div>
                              {{ $t("AUTH.MENTOR_INFO_2") }}
                            </div>
                          </div>
                          <div>
                            <div class="info">
                              <div class="ellipse blue"></div>
                              {{ $t("AUTH.MENTOR_INFO_3") }}
                            </div>
                          </div>
                          <div style="margin-bottom: 40px">
                            <div class="info">
                              <div class="ellipse blue"></div>
                              {{ $t("AUTH.MENTOR_INFO_4") }}
                            </div>
                          </div>
                          <router-link to="/auth/create-account">
                            <base-button block variant="secondary">
                              {{ $t('AUTH.CREATE_MENTOR_ACCOUNT') }}</base-button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="4" md="12">
                  <div class="container">
                    <div class="w-100">
                      <div>
                        <div class="get-started-title">
                          {{ $t("AUTH.USER_ACCOUNT") }}
                        </div>

                        <div class="get-started-subtitle" style="margin-bottom: 40px">
                          {{ $t("AUTH.USER_ACCOUNT_DESCRIPTION") }}
                        </div>

                        <div>
                          <div class="info">
                            <div class="ellipse blue "></div>
                            {{ $t("AUTH.USER_INFO_1") }}
                          </div>
                        </div>
                        <div>
                          <div class="info">
                            <div class="ellipse blue "></div>
                            {{ $t("AUTH.USER_INFO_2") }}
                          </div>
                        </div>
                        <div>
                          <div class="info">
                            <div class="ellipse blue"></div>
                            {{ $t("AUTH.USER_INFO_3") }}
                          </div>
                        </div>
                        <div style="margin-bottom: 48px">
                          <div class="info">
                            <div class="ellipse blue"></div>
                            {{ $t("AUTH.USER_INFO_4") }}
                          </div>
                        </div>
                        <div>
                          <a class="btn-primary w-100"
                             style="display: flex; justify-content: center"
                             target="_blank" href="https://apps.apple.com/us/app/edneos/id1623354808">
                            <img src='/media/auth/app_store.svg' alt=""/>
                            {{ $t('AUTH.DOWNLOAD_ON_APP_STORE') }}
                          </a>
                        </div>
                        <div style="margin-top: 20px">
                          <a class="btn-primary w-100"
                             style="display: flex; justify-content: center"
                             target="_blank" href="https://play.google.com/store/apps/details?id=com.edneos">
                            <img src='/media/auth/google_play.svg' alt=""/>
                            {{ $t('AUTH.DOWNLOAD_ON_GOOGLE_PLAY') }}
                          </a>
                        </div>
                           <div style="justify-content: center; display: flex; margin-top: 40px">
                             <img src='/media/auth/qr.svg' alt=""
                                  style="height: 80px;width: 80px"/>
                           </div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col lg="4" md="12">
                  <div class="container">
                    <div class="w-100">
                      <div>
                        <div class="get-started-title">
                          {{ $t("AUTH.SCHOOL_ACCOUNT") }}
                        </div>

                        <div class="get-started-subtitle" style="margin-bottom: 40px">
                          {{ $t("AUTH.SCHOOL_ACCOUNT_DESCRIPTION") }}
                        </div>
                        <div>
                          <div class="info">
                            <div class="ellipse blue"></div>
                            {{ $t("AUTH.SCHOOL_INFO_1") }}
                          </div>
                        </div>
                        <div>
                          <div class="info">
                            <div class="ellipse blue "></div>
                            {{ $t("AUTH.SCHOOL_INFO_2") }}
                          </div>
                        </div>
                        <div>
                          <div class="info">
                            <div class="ellipse blue"></div>
                            {{ $t("AUTH.SCHOOL_INFO_3") }}
                          </div>
                        </div>
                        <div style="margin-bottom: 40px">
                          <div class="info">
                            <div class="ellipse blue"></div>
                            {{ $t("AUTH.SCHOOL_INFO_4") }}
                          </div>
                        </div>
                        <router-link to="/auth/create-school-account">
                          <base-button block variant="secondary">
                            {{ $t('AUTH.SUBMIT_FOR_SCHOOL_ACCOUNT') }}</base-button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            <!--end::Form-->
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>

 </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '../../../shared/components/BaseButton.vue';

let agreeTerms = false;
let agreeTermsMentor = false;
export default {
  name: 'CreateAccount',
  components: { BaseButton },
  data() {
    return {
      agreeTerms: false,
      agreeTermsMentor: false,
      componentKey: 0,
      model: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    changeValue() {
      agreeTerms = !agreeTerms;
    },
    changeValueMentor() {
      agreeTermsMentor = !agreeTermsMentor;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/auth-layout.scss';
.sh-auth-content.create{
  background-color: transparent!important;
  padding: 0 50px 50px 50px;
  .container{
    font-family: Inter;
    background-color: #F1F3F6;
    border-radius: 8px;
    height: fit-content;
    display: inline-flex;
    padding: 40px;
    align-content: center;
    align-self: center;
    justify-content: center;
    //align-items: center;
    width: -webkit-fill-available;
    min-height: 615px;
    margin-bottom: 20px;
  }

  .get-started-title{/* Mentor Account */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */
    text-align: center;
    letter-spacing: 0.5px;

    color: #333333;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    margin-bottom: 8px;
  }
  .get-started-subtitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */
    display: flex;
    align-items: center;
    text-align: center;
    /* black100 */
    color: #000000;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

  }
  .ellipse.blue{
    display: inline-flex;
    width: 8px;
    height: 8px;
    background-color: #1694D0;
    border-radius: 50%;
  }
  .info{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* identical to box height, or 16px */
    display: flex;
    align-items: center;
    /* black100 */
    color: #000000;
    margin-bottom: 8px;
  }
}
</style>
